<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton :disabled="disabled">
        <slot />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-neutral-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer"
        :class="{ 'right-0': position === 'left', 'left-0': position === 'right' }"
      >
        <div class="py-1">
          <MenuItem v-for="(option, index) in options" :key="index" class="flex flex-col text-xsm font-normal">
            <a
              @click="$emit(option.value, option.metadata)"
              class="block px-4 py-2 text-sm"
              :class="`${option.color} bg-neutral-white hover:bg-neutral-100`"
            >
              <span class="flex items-center" :style="{ color: option.color }">
                <component :is="AvailableIcons[option.icon]" class="h-4 w-4 mr-2 fill-current" />
                {{ option.label }}
              </span>
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import * as Icons from '../icons';

const AvailableIcons: Record<string, any> = Icons;

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: null,
  },
  options: {
    type: Array as PropType<{ icon: string; label: string; value: string; metadata?: unknown; color?: string }[]>,
    default: () => [],
  },
  icon: {
    type: String,
    default: null,
  },
  variant: {
    type: String,
    validator: (variant: string) => ['primary', 'secondary', 'destructive'].includes(variant),
    default: 'primary',
  },
  round: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'medium',
    validator: (size: string) => ['small', 'medium', 'large', 'fluid'].includes(size),
  },
  position: {
    type: String,
    default: 'left',
  },
});
</script>
